///////////////   A L L   A C C O U N T - R E L A T E D   F U N C T I O N A L I T Y   ///////////////

/*   ***************   oAuth Login   ***************   */

/*   ***************   Password Reset   ***************   */
let passwordResetForm = document.querySelector('#password-reset-form');
if (passwordResetForm) {
    function updatePasswordResetPage(event) {
        console.log('password submitted');

        // Hide content that should only shown BEFORE the form is submitted
        let presubmitContent = document.querySelectorAll('.js-presubmit');
        presubmitContent.forEach((el) => {
            el.classList.add('hidden');
        });

        // And show the content that should be shown AFTER
        let postsubmitContent = document.querySelectorAll('.js-postsubmit');
        postsubmitContent.forEach((el) => {
            el.classList.remove('hidden');
        });
    }

    passwordResetForm.addEventListener('submit', function(e){
        updatePasswordResetPage(e);
    });
}